@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:wght@400..900&display=swap');

@tailwind base;

@tailwind components;

@tailwind utilities;

p, h1,h2,h3,h4 {
    color: rgb(234, 234, 234);
}


@layer utils {
    .first-component {
        @apply w-full min-h-screen pt-20 overflow-hidden flex items-center
    }
    .component {
        @apply w-full overflow-hidden
    }
    .section-container {
        @apply container mx-auto py-8;
    }
  }
